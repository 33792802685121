import { ReactNode, useMemo, useReducer } from 'react';
import DictionaryContext from './DictionaryContext';
import dictionaryReducer, {
  DictionaryBucketEntry,
  DICTIONARY_BUCKET_ACTION,
  newEmptyDictionaryEntry,
} from './DictionaryReducer';

export enum DictionaryEntryField {
  ORIGINAL = 'original',
  ADAPTION = 'adaption',
  TYPE = 'type',
}

export interface DictionaryBucketState {
  entries: DictionaryBucketEntry[];
  shouldScroll: boolean;
}

export const initialDictionaryBucketState: DictionaryBucketState = {
  entries: [newEmptyDictionaryEntry()],
  shouldScroll: false,
};

type DictionaryProviderProps = {
  children: ReactNode;
  initialState?: DictionaryBucketState;
};
const DictionaryProvider = ({ children, initialState = initialDictionaryBucketState }: DictionaryProviderProps) => {
  const [state, dispatch] = useReducer(dictionaryReducer, initialState);

  const setShouldScroll = (shouldScroll: boolean) => {
    state.shouldScroll = shouldScroll;
  };

  const addEntry = (original: string, append = true) => {
    const entry: DictionaryBucketEntry = { ...newEmptyDictionaryEntry(), original: original, adaption: original };
    dispatch({ type: DICTIONARY_BUCKET_ACTION.ADD_ENTRY, payload: { entry: entry, append: append } });
    state.shouldScroll = true;
  };

  const changeEntry = (id: string, update: Partial<DictionaryBucketEntry>) => {
    dispatch({ type: DICTIONARY_BUCKET_ACTION.CHANGE_ENTRY, payload: { id, update } });
  };

  const removeEntry = (id: string) => {
    dispatch({ type: DICTIONARY_BUCKET_ACTION.REMOVE_ENTRY, payload: { id } });
  };

  const resetAll = () => {
    dispatch({ type: DICTIONARY_BUCKET_ACTION.RESET, payload: null });
  };

  const startSavingEntry = (id: string) => {
    dispatch({
      type: DICTIONARY_BUCKET_ACTION.CHANGE_ENTRY,
      payload: { id, update: { loading: true } },
    });
  };

  const finishSavingEntry = (id: string, error?: string) => {
    if (error) {
      dispatch({
        type: DICTIONARY_BUCKET_ACTION.CHANGE_ENTRY,
        payload: { id, update: { loading: false, error } },
      });
    } else {
      dispatch({ type: DICTIONARY_BUCKET_ACTION.REMOVE_ENTRY, payload: { id } });
    }
  };

  const memoizedValue = useMemo(() => {
    return {
      entries: state.entries,
      addEntry,
      changeEntry,
      removeEntry,
      startSavingEntry,
      finishSavingEntry,
      resetAll,
      shouldScroll: state.shouldScroll,
      setShouldScroll,
    };
  }, [state.entries, state.shouldScroll]);

  return <DictionaryContext.Provider value={memoizedValue}>{children}</DictionaryContext.Provider>;
};

export default DictionaryProvider;
