import { createContext, FunctionComponent, memo, PropsWithoutRef, useContext } from 'react';
import DictionaryProvider, { DictionaryBucketState, initialDictionaryBucketState } from './DictionaryProvider';
import { DictionaryBucketEntry } from './DictionaryReducer';

interface DictionaryBucketStateWithActions extends DictionaryBucketState {
  addEntry: (original: string, append?: boolean) => void;
  changeEntry: (id: string, update: Partial<DictionaryBucketEntry>) => void;
  removeEntry: (id: string) => void;
  startSavingEntry: (id: string) => void;
  finishSavingEntry: (id: string, error?: string) => void;
  resetAll: () => void;
  setShouldScroll: (shouldScroll: boolean) => void;
  shouldScroll: boolean;
}

export const dictionaryBucketContextDefaultValue: DictionaryBucketStateWithActions = {
  ...initialDictionaryBucketState,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  addEntry: (_original, _append) => undefined,
  changeEntry: (_id, _update) => undefined,
  removeEntry: (_id) => undefined,
  startSavingEntry: (_id) => undefined,
  finishSavingEntry: (_id) => undefined,
  resetAll: () => undefined,
  setShouldScroll: (_shouldScroll: boolean) => undefined,
  shouldScroll: false,
  /* eslint-enable @typescript-eslint/no-unused-vars */
};

const DictionaryContext = createContext<DictionaryBucketStateWithActions>(dictionaryBucketContextDefaultValue);

const useDictionaryContext = () => useContext(DictionaryContext);

// eslint-disable-next-line react/display-name
const withDictionaryContext = (WrappedComponent: FunctionComponent) => (props: PropsWithoutRef<any>) => {
  const MemoizedWrappedComponent = memo(WrappedComponent);
  return (
    <DictionaryProvider>
      <MemoizedWrappedComponent {...props} />
    </DictionaryProvider>
  );
};

export { useDictionaryContext, withDictionaryContext };

export default DictionaryContext;
