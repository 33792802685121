import { v4 as uuidv4 } from 'uuid';
import { DictionaryEntryType } from '../../../../models/Dictionary';
import { DictionaryBucketState } from './DictionaryProvider';

export enum DICTIONARY_BUCKET_ACTION {
  ADD_ENTRY = 'ADD_ENTRY',
  CHANGE_ENTRY = 'CHANGE_ENTRY',
  REMOVE_ENTRY = 'REMOVE_ENTRY',
  RESET = 'RESET',
}

export const EMPTY_ENTRY_TEMPLATE = {
  original: '',
  type: DictionaryEntryType.ENGLISH,
  adaption: '',
  savable: false,
  loading: false,
  error: null,
};

export type DictionaryBucketEntry = {
  adaption: string;
  original: string;
  id: string;
  type: DictionaryEntryType;
  loading: boolean;
  error: string | null;
  savable: boolean;
};

export const newEmptyDictionaryEntry = (): DictionaryBucketEntry => {
  return { ...EMPTY_ENTRY_TEMPLATE, id: uuidv4() };
};

const valueHasChanged = (field: 'adaption' | 'original', entry: DictionaryBucketEntry) => {
  return Boolean(entry[field] && entry[field] !== EMPTY_ENTRY_TEMPLATE[field]);
};

const updateSavableFlag = (entry: DictionaryBucketEntry): DictionaryBucketEntry => {
  const isLoading = () => entry.loading;
  const hasError = () => entry.error !== null;

  const isSavable = () =>
    valueHasChanged('original', entry) && valueHasChanged('adaption', entry) && !isLoading() && !hasError();

  if (isSavable()) {
    return { ...entry, savable: true };
  }

  return { ...entry, savable: false };
};

const getEntryIndex = (entries: DictionaryBucketEntry[], id: string) => {
  return entries.findIndex((entry) => entry.id === id);
};

type DictionaryBucketActionCmd = {
  type: DICTIONARY_BUCKET_ACTION;
  payload: any;
};

const dictionaryReducer = (state: DictionaryBucketState, action: DictionaryBucketActionCmd) => {
  const { type, payload } = action;

  switch (type) {
    case DICTIONARY_BUCKET_ACTION.ADD_ENTRY: {
      const entry = payload.entry;
      // If the new entry already exists, return the list unchanged
      const entryExists =
        entry.original.trim() !== '' &&
        entry.adaption.trim() !== '' &&
        state.entries.some((item) => {
          return entry.original === item.original && entry.adaption === item.adaption;
        });

      if (entryExists) {
        return state;
      }

      // Removing the placeholder entry
      const oldEntries = state.entries.filter((item) => item.original.trim() !== '' || item.adaption.trim() !== '');
      const newEntry = updateSavableFlag(entry);

      state.shouldScroll = true;

      return {
        ...state,
        entries: payload.append ? [...oldEntries, newEntry] : [newEntry, ...oldEntries],
      };
    }
    case DICTIONARY_BUCKET_ACTION.CHANGE_ENTRY: {
      const id = payload.id;
      const update: Partial<DictionaryBucketEntry> = payload.update;

      const entriesWithUpdatedEntry = state.entries.map((entry) => {
        if (entry.id !== id) {
          return entry;
        }
        return updateSavableFlag({
          ...entry,
          loading: EMPTY_ENTRY_TEMPLATE.loading,
          error: EMPTY_ENTRY_TEMPLATE.error,
          ...update,
        });
      });

      return {
        ...state,
        entries: entriesWithUpdatedEntry,
      };
    }
    case DICTIONARY_BUCKET_ACTION.REMOVE_ENTRY: {
      const entryIdx = getEntryIndex(state.entries, payload.id);

      if (entryIdx === -1) {
        return state;
      }

      if (state.entries.length === 1) {
        return {
          ...state,
          entries: [newEmptyDictionaryEntry()],
        };
      }

      const newEntries = state.entries.filter((_, idx) => idx !== entryIdx);

      return {
        ...state,
        entries: newEntries,
      };
    }
    case DICTIONARY_BUCKET_ACTION.RESET: {
      return {
        ...state,
        entries: [newEmptyDictionaryEntry()],
      };
    }
    default:
      return state;
  }
};

export default dictionaryReducer;
